import 'magnific-popup';

jQuery( document ).ready( function ( $ ) {
	// Nav
	$( 'nav>ul>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		$( 'nav>ul>li>a' ).removeClass( 'active' );
		$( this ).addClass( 'active' );

		$( 'section:visible' ).fadeOut( 400, function () {
			$( href ).fadeIn( 400 );
		} );
	} );

	// Record
	/*$( '.record>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).toggleClass( 'open' );
	} );*/

	// Form
	$( 'input[type=email]' ).focus( function () {
		$( this ).attr( 'placeholder', '' );
	} ).blur( function () {
		$( this ).attr( 'placeholder', 'Your Email' );
	} );

	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		var action = $( this ).attr( 'action' );
		var form_data = $( this ).serialize();
		var btnNormal = $( this ).find( '.btn-submit' ).html();
		var btnLoad = $( this ).find( '.btn-submit' ).attr( 'data-load' );
		$( this ).find( '.btn-submit' ).text( btnLoad );

		$.post( action, form_data, function ( response, statusText, xhr ) {
			console.log( response );
			switch ( xhr.status ) {
				case 200:
					$( 'form' ).find( '.btn-submit' ).html( btnNormal );
					$( '.Form-Wrapper' ).hide();
					$( '.thankyou-mailinglist' ).show();
					break;
				case 400:
					alert( response.error_message );
					break;
				case 404:
					alert( response.error_message );
					break;
				case 410:
					alert( response.error_message );
					break;
				case 403:
					alert( response.error_message );
					break;
			}
		}, 'json' )
		 .fail( function ( response ) {
			 $( 'form' ).find( '.btn-submit' ).html( btnNormal );
			 alert( 'Something went wrong! Please try again.' );
		 } );
	} );

	$.magnificPopup.open( {
		items: {
			src: '#album',
			type: 'inline'
		},
		closeBtnInside: false
	} );

	$( '.tour-btn' ).on( 'click', function ( e ) {
		e.preventDefault();
		$.magnificPopup.close();
		$( 'nav>ul>li:nth-child(3)>a' ).trigger( 'click' );
	} );
} );
